<template>
  <div class="competition_page">
    <div class="box_bg">
      <div class="bg_title">
        <div class="bg_title1">中蟋网交易市场</div>
        <div class="bg_title2">各地蟋蟀市场行情</div>
      </div>
    </div>
    <div class="container">
      <div
        class="item flex align-items-center justify-content-between"
        v-for="(item, index) in List"
        :key="index"
        @click="navigateTo(`/articleDetail/crticleDetail/${item.id}`)"
      >
        <div class="i_left flex align-items-center justify-content-between">
          <div class="box_img">
            <img src="../../static/logo.png" alt="" />
          </div>
          <div class="box_timer">
            <div class="box_timer_1">2022</div>
            <div class="box_timer_2">01-25</div>
          </div>
        </div>
        <div class="i_right flex align-items-center justify-content-between">
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <!-- <div class="desc">123213</div> -->
          </div>
          <div><i class="el-icon-arrow-right"></i></div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="amount"
        :page-size="10"
        :current-page="page"
        @current-change="currentChange"
        @prev-click="prevPage"
        @next-click="nextPage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      amount: 0,
      current: 0,
      List: [],
      page: 1,
    };
  },
  methods: {
    currentChange(e) {
      this.page = e;
      this.getList();
    },
    prevPage(e) {
      this.page = e;
      this.getList();
    },
    nextPage(e) {
      this.page = e;
      this.getList();
    },
    choose(i) {
      this.page = 1;
      console.log(i);
      this.current = i;
      this.getList();
    },
    getList() {
      this.$http
        .CompetitionList(`&page=${this.page}` + `&size=${10}`)
        .then((res) => {
          console.log(res);
          this.List = res.data.articles;
        });
    },
  },
  mounted() {
    this.getList();
  },
  created () {
    document.title = '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！'
 },
};
</script>
<style lang="scss" scoped>
.competition_page {
}
.box_bg {
  width: 100%;
  height: 370px;
  background-image: url("../../static/bg4.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  .bg_title {
    position: absolute;
    width: 950px;
    background-color: rgb(247, 247, 247);
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    padding: 20px;
    .bg_title1 {
      font-size: 16px;
      font-weight: 800;
    }
    .bg_title2 {
      line-height: 30px;
    }
  }
}
.container {
  width: 950px;
  margin: 50px auto;
  .item {
    width: 100%;
    background-color: rgb(247, 247, 247);
    padding: 5px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 5px #bdbbbb;
    cursor: pointer;
    .i_left {
      width: 230px;
      .box_img {
        width: 120px;
        height: 80px;
        img {
          height: 100%;
          overflow: hidden;
        }
      }
      .box_timer {
        width: 80px;
        text-align: center;
        .box_timer_1 {
          font-size: 22px;
        }
        .box_timer_2 {
          font-size: 16px;
          color: rgb(187, 187, 187);
        }
      }
    }
    .i_right {
      width: calc(100% - 230px);
      padding-right: 20px;
      .content {
        width: 660px;
        padding: 0 10px;
        border-left: 1px solid #ccc;
        .title {
          font-size: 16px;
          font-weight: 800;
        }
        .desc {
          padding-top: 5px;
        }
      }
    }
  }
}
.pagination {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #c70707; //修改后的背景图颜色
    color: #fff;
  }
}
</style>